import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../styles/Layout.scss";
import { Container, Row } from "react-bootstrap";

const Layout = ({ children }) => (
  <>
    <Container fluid className="Layout">
        <Navbar />
      <Container className="Layout__Container">
        {children}
      <Footer />
      </Container>
    </Container>
  </>
);

export default Layout;
