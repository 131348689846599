import React, { useRef, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import ContentsQuery from "../../Components/Contents.query";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { Container, Col, Row } from "react-bootstrap";
import { PhoneCall, MapPin } from 'react-feather'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import "../../styles/Contact.scss"

const Contact = () => {
  const [contactContent, setContactContent] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState()
  const { control } = useForm()

  const { title } = useParams();
  const { loading, error, data } = useQuery(ContentsQuery);
  const { register, handleSubmit, errors } = useForm()


  useEffect(() => {
    if (data) {
      setContactContent(data.contacts.find((cont) => cont.title === title));
    }
  }, [data]);

  const form = useRef();
  if (error) return <p>Error :</p>;

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fzx6zwl",
        "template_93whwcv",
        form.current,
        "QT34wLHHUaFzd5800"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message Sent Successfully")
          document.getElementById('form').reset()
          document.getElementById('formPhone').value=''
        },
        (error) => {
          console.log(error.text);
          toast.error("Something went wrong")
        },
        );
      };
  const checkFilled = (input) => {
    if (!input.value) {
      input.style.backgroundColor = 'red'
    } else {
      input.style.backgroundColor = null
    }
  }

  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        type="success"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading && (
        <div className="Loading">
          <img src="../../../loading.gif" alt="loading" />
        </div>
      )}
      {contactContent && (
        <Container className="Contact">
          <Container className="Contact__Container col-xl-11 col-12">
            <Row className="Contact__Title">
              <h1>{contactContent.mainTitle}</h1>
            </Row>
            <Row className="About__WrapperLine col col-xl-11"></Row>
            <Row className="Contact__Container__Info">
              <a href="mailto:info@keesht.ir" className="Contact__Container__Info__Detail__1 col-xl-3 col-lg-3 col-md-3 col-sm-9">
                <div className="Contact__Container__Info__Detail__1__Mail">
                  <div className="Contact__Container__Info__Detail__1__Mail__Cover"></div>
                  <div className="Contact__Container__Info__Detail__1__Mail__Letter"><h1>@</h1></div>
                </div>
                {contactContent.email}
              </a>
              <a href="tel:+982188982500" className="Contact__Container__Info__Detail__2 col-xl-3 col-lg-3 col-md-3 col-sm-9">
                <div>
                  <PhoneCall />
                </div>
                {contactContent.phoneNumber}
              </a>
              <a className="Contact__Container__Info__Detail__3 col-xl-3 col-lg-3 col-md-3 col-sm-9">
                <div>
                  <MapPin />
                </div>
                {contactContent.shortLocation}
              </a>
            </Row>

            <form id='form' className="Contact__Container__Form" ref={form} onSubmit={sendEmail} >
              <Row className="Contact__Container__Form__Wrapper">
                <Col className="Contact__Container__Form__Wrapper__Inputs col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Your Name</label>
                  <input type="text" name="user_name" placeholder="Full Name" required />
                  <label>Subject</label>
                  <input type="subject" name="user_subject" placeholder="Subject" required />
                  <label>Email</label>
                  <input type="email" name="user_email" placeholder="example.email.com" required />
                  <label>Number</label>
                  <PhoneInput
                    id='formPhone'
                    international
                    countryCallingCodeEditable={false}
                    placeholder="Phone Number"
                    defaultCountry="IR"
                    name='user_number'
                    value={phoneNumber}
                    onChange={setPhoneNumber} />
                </Col>
                <Col className="Contact__Container__Form__Wrapper__Message col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Message</label>
                  <textarea name="message" placeholder="Message" required />
                </Col>
              </Row>
              <Row className="Contact__Container__Form__Wrapper__Button">
                <Col>
                  <input type="submit" value="Send Message" />
                </Col>
              </Row>
            </form>
          </Container>
        </Container>
      )}
    </>
  );
};

export default Contact;
