import { gql } from "@apollo/client";

const CONTENT_QUERY = gql`
  {
    posts{
      blogTitle
      id
      title
      summary
      slug
      coverPhoto{
        url
      }
      postId
      datePublished
      content{
        html
      }
      author{
        name
      }
    }
    section2S {
      title
      content
      cover {
        url
      }
      background {
        url
      }
    }

    imgAssets {
      wrapperLine {
        url
      }
      darkLogo {
        url
      }
      loadingGif{
        url
      }
    }
    section3S {
      mainTitle
      title
      content
      icon {
        url
      }
    }
    section4S {
      mainTitle
      title
      subtitle1
      subtitle2
      subtitle3
      subtitle4
      subtitle5
    }
    section5S {
      mainTitle
      name
      testimony
      avatar {
        url
      }
    }
    abouts{
      mainTitle
      subTitle1
      content1{
        html
      }
      subTitle2
      subTitle3
      content3{
        html
      }
      subTitle4
      content4{
        html
      }
    }
    aboutTeams{
      teamMember{
        url
      }
      teamMemberTitle
    }
    contacts{
      mainTitle
      phoneNumber
      location
      location2
      shortLocation
      email
      description{
        html
      }
    }
  }
`;

export default CONTENT_QUERY;
