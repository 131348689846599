import React from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import ContentsQuery from "../../../Components/Contents.query";

const Section2 = () => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;

  return (
    <>
      <Col className="Home__Wrapper__Section__2__Container">
        {loading && (
          <div className="Loading">
            <img src="../../../loading.gif" alt="Loading" />
          </div>
        )}
        <Row className="Home__Wrapper__Section__2__Container__Wrapper mb-5 mt-5">
          {data &&
            data.imgAssets.map((wrapper) => (
              <img
                key={wrapper}
                src={wrapper.wrapperLine.url}
                alt="wrapperLine"
              />
            ))}
        </Row>
        <Row className="Home__Wrapper__Section__2__Container__Content">
          <Row className="Home__Wrapper__Section__2__Container__Content__Title mb-5">
            {data &&
              data.section2S.map((content) => (
                <h3 key={content}>{content.title}</h3>
              ))}
          </Row>
          <Row className="Home__Wrapper__Section__2__Container__Content__ImgPar">
            <Col className="col-11 col-sm-10 col-xl-5 col-lg-5 col-md-5 Home__Wrapper__Section__2__Container__Content__ImgPar__Wrapper">
              <div></div>
              {data &&
                data.section2S.map((content) => (
                  <img
                    key={content}
                    src={content.cover.url}
                    width="100%"
                    alt="section2image"
                  />
                ))}
            </Col>
            <Col className="col-11 col col-lg-6 col-md-6 col-sm-10">
              {data &&
                data.section2S.map((content) => (
                  <p key={content}>{content.content}</p>
                ))}
            </Col>
            {/* <Col className="col-md-1 col-sm-10 col-1 col-lg-1 col-xl-1 col-10"></Col> */}
          </Row>
        </Row>
        <Row className="Home__Wrapper__Section__2__Container__Background">
          {data &&
            data.section2S.map((content) => (
              <img
                key={content}
                src={content.background.url}
                alt="section2Bg"
              />
            ))}
        </Row>
      </Col>
    </>
  );
};

export default Section2;
