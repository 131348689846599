import React from "react";
import { Col, Row } from "react-bootstrap";
import TestimonialsSlider from "../../../Components/TestimonialsSlider";
import { useQuery } from "@apollo/client";
import ContentsQuery from "../../../Components/Contents.query";

const Section5 = () => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;
  return (
    <>
      <Col className="Home__Wrapper__Section__5__Container">
        {loading && (
          <div className="Loading">
            <img src="../../../loading.gif" alt="Loading" />
          </div>
        )}
        <Row className="Home__Wrapper__Section__5__Container__Wrapper mb-5 mt-5">
          {data &&
            data.imgAssets.map((wrapper) => (
              <img
                key={wrapper}
                src={wrapper.wrapperLine.url}
                alt="wrapperLine"
              />
            ))}
        </Row>
        <Row className="Home__Wrapper__Section__5__Container__Content">
          <Row className="Home__Wrapper__Section__5__Container__Content__Title mb-5">
            {data &&
              data.section5S.map((mainTitle, mainTitle5) => (
                <h3 key={mainTitle5}>{mainTitle.mainTitle}</h3>
              ))}
          </Row>
        </Row>
        <TestimonialsSlider />
      </Col>
    </>
  );
};

export default Section5;
