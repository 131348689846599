import React from "react";
import { useQuery } from "@apollo/client";
import ContentsQuery from "../../Components/Contents.query";
import BlogCard from "../../Components/BlogCard";
import "../../styles/Blog.scss";

const Blog = () => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;

  return (
    <>
    {loading && (
          <div className="Loading">
            <img src="../../../loading.gif" alt="Loading" />
          </div>
        )}
      {data &&
        data.imgAssets.map((wrapper) => (
          <img
            width="100%"
            key={wrapper}
            src={wrapper.wrapperLine.url}
            alt="wrapperLine"
          />
        ))}
        {data && <h2>{data.posts.blogTitle}</h2>}
      <h2>Keesht Blog</h2>
      <div className="Blog col col-xl-11">
        {data && data.posts.map((post) => (
            <BlogCard
              key={post.id}
              title={post.title}
              author={post.author}
              coverPhoto={post.coverPhoto}
              datePublished={post.datePublished}
              slug={post.slug}
              summary={post.summary}
            />
          ))}
      </div>
    </>
  );
};

export default Blog;
