import "../styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "../Pages/Home/Home";
import Layout from "./Layout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Blog from "../Pages/Blog/Blog";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
import Error from "../Pages/Error";
import { Route, Routes } from "react-router-dom";
import BlogPost from '../Pages/Blog/posts/[slug]'
function App() {

  return (
    <div className="Keesht">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path={`/blog/posts/:slug`} element={<BlogPost />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
