import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/lazy';
import '../styles/TestimonialsSlider.scss'
import QuoteIcon from '../assets/icons/quote.png'
import { useQuery } from "@apollo/client";
import ContentsQuery from './Contents.query'

const TestimonialsSlider = () => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;
  return (
    <Swiper
      className="Testimonials__Slider"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      lazy={2}
      breakpoints={{
        
        992: {
          slidesPerView: 2.1,
        }
      }}
    >
      {loading && <div>Loading...</div>}
      {data && data.section5S.map((testimony, testimonyKey) => (
        <SwiperSlide key={testimonyKey} className="Testimonials__Slider__Slide" >
          <Col className="Testimonials__Slider__Slide__Container">
            <Row className="Testimonials__Slider__Slide__Container__Quote">
              <img src={QuoteIcon} alt="quote" />
            </Row>
            <Row className="Testimonials__Slider__Slide__Container__Content">
              <div className="Testimonials__Slider__Slide__Container__Content__User">
                <Row className="Testimonials__Slider__Slide__Container__Content__User__Image">
                  <img src={testimony.avatar.url} alt="avatar" />
                </Row>
                <Row className="Testimonials__Slider__Slide__Container__Content__User__Name">
                  <h6>{testimony.name}</h6>
                </Row>
              </div>
              <Col className="Testimonials__Slider__Slide__Container__Content__Testemony">
                <p>{testimony.testimony}</p>
              </Col>
            </Row>
          </Col>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default TestimonialsSlider