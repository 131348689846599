import React from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-feather";
import { useQuery } from "@apollo/client";
import ContentsQuery from "../../../Components/Contents.query";

const Section4 = () => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;
  return (
    <>
      <Col className="Home__Wrapper__Section__4__Container">
        {loading && (
          <div className="Loading">
            <img src="../../../loading.gif" alt="Loading" />
          </div>
        )}
        <Row className="Home__Wrapper__Section__4__Container__Wrapper mb-5 mt-5">
          {data &&
            data.imgAssets.map((wrapper) => (
              <img
                key={wrapper}
                src={wrapper.wrapperLine.url}
                alt="wrapperLine"
              />
            ))}
        </Row>
        <Row className="Home__Wrapper__Section__4__Container__Content">
          <Row className="Home__Wrapper__Section__4__Container__Content__Title mb-5">
            {data &&
              data.section4S.map((mainTitle, mainTitle3) => (
                <h3 key={mainTitle3}>{mainTitle.mainTitle}</h3>
              ))}
          </Row>
          <Row className="Home__Wrapper__Section__4__Container__Content__Wrapper col-12 col-sm-11 col-lg-11 col-xl-12">
            {data &&
              data.section4S.map((content, content3) => (
                <Col
                  key={content3}
                  className="Home__Wrapper__Section__4__Container__Content__Wrapper__Box col-10 col-xxl-5 col-xl-5 col-lg-5 col-md-10"
                >
                  <Row className="Home__Wrapper__Section__4__Container__Content__Wrapper__Box__Title">
                    <div></div>
                    <h4>{content.title}</h4>
                  </Row>
                  <Row className="Home__Wrapper__Section__4__Container__Content__Wrapper__Box__Titles">
                    <div>
                      <div></div>
                      <h5>{content.subtitle1}</h5>
                    </div>
                    <div>
                      <div></div>
                      <h5>{content.subtitle2}</h5>
                    </div>
                    <div>
                      <div></div>
                      <h5>{content.subtitle3}</h5>
                    </div>
                    <div>
                      <div></div>
                      <h5>{content.subtitle4}</h5>
                    </div>
                    <div>
                      <div></div>
                      <h5>{content.subtitle5}</h5>
                    </div>
                  </Row>
                  {/* <Row className="Home__Wrapper__Section__4__Container__Content__Wrapper__Box__Button">
                    <a>
                      Click Here
                      <ArrowRightCircle strokeWidth="1" />
                    </a>
                  </Row> */}
                </Col>
              ))}
          </Row>
        </Row>
      </Col>
    </>
  );
};

export default Section4;
