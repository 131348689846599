import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../styles/Error.scss'

const Error = () => {
  return (
    <Container fluid className="Error">
      <Col className="Error__Content col-xl-6 col-12">
        <Row className="Error__Content__404">
          <h1>404</h1>
        </Row>
        <Row className="Error__Content__Header">
          <h1>Oooops! You waren't supposed to see this</h1>
        </Row>
        <Row className="Error__Content__Text">
          <h6>The page you're looking for no longer exists.<br />Return to the <Link to="/">Home.Page</Link> and remember: you haven't seen anything.</h6>
        </Row>
      </Col>
      <Col></Col>
    </Container>
  )
}

export default Error