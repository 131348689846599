import React from "react";
import "../../styles/Home.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Col, Row } from "react-bootstrap";
import Section1 from "./Components/Section1";
import Section2 from "./Components/Section2";
import Section3 from "./Components/Section3";
import Section4 from "./Components/Section4";
import Section5 from "./Components/Section5";

const Home = () => {
  return (
    <div className="Home col col-xl-10">
      <Row className="Home__Wrapper__Section__1">
        <Section1 />
      </Row>
      <Row className="Home__Wrapper__Section__2">
        <Section2 />
      </Row>
      <Row className="Home__Wrapper__Section__3">
        <Section3 />
      </Row>
      <Row className="Home__Wrapper__Section__4">
        <Section4 />
      </Row>
      <Row className="Home__Wrapper__Section__5">
        <Section5 />
      </Row>
    </div>
  );
};
export default Home;
