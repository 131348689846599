import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import ContentsQuery from "./Contents.query";
import { Calendar, User } from "react-feather";

const BlogCard = ({
  post,
  title,
  author,
  coverPhoto,
  datePublished,
  slug,
  summary,
}) => {
  const { loading, error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;

  return (
    <Col className="Blog__BlogCard col-xl-4 col-lg-5 col-md-8 col-sm-8 col-10">
      {loading && (
        <div className="Loading">
          <img src="../loading.gif" alt="Loading" />
        </div>
      )}
      <Link to={`/blog/posts/` + slug}>
        <Row className="Blog__BlogCard__Cover">
          <img width={300} src={coverPhoto.url} alt="coverPhoto" />
        </Row>
      </Link>
      <Row className="Blog__BlogCard__Content">
        <Row className="Blog__BlogCard__Content__Title">
          <Link to={`/blog/posts/` + slug}>
            <h3>{title}</h3>
          </Link>
        </Row>
        <Row className="Blog__BlogCard__Content__Summary">
          <h5>{summary}</h5>
        </Row>
        <Row className="Blog__BlogCard__Content__Date">
          <div>
            <Calendar />
            <h6>{datePublished}</h6>
          </div>
          <div>
            <User />
            <h6>{author.name}</h6>
          </div>
        </Row>
      </Row>
    </Col>
  );
};

export default BlogCard;
