import React, { useState } from "react";
import Logo from "../assets/items/Logo.png";
import ActiveIcon from "../assets/icons/navbar-icon.png";
import { Instagram, Twitter, Linkedin } from "react-feather";
import "../styles/Navbar.scss";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Navbar = () => {
  const [active, setActive] = useState("Navbar__Menu");
  const [unactive, setUnactive] = useState("Navbar__Menu");
  const [toggleIcon, setToggleIcon] = useState("Navbar__Toggler");
  const [navbarBg, setNavbarBg] = useState(false);
  const navbarToggle = () => {
    active === "Navbar__Menu"
      ? setActive("Navbar__Menu Navbar__Active")
      : setActive("Navbar__Menu");

    toggleIcon === "Navbar__Toggler"
      ? setToggleIcon("Navbar__Toggler Navbar__Toggle")
      : setToggleIcon("Navbar__Toggler");

    unactive === "Navbar__Menu Navbar__Toggler"
      ? setUnactive("Navbar__Toggler")
      : setUnactive("Navbar__Menu");
  }
  const navbarBG = () => {
    if (window.scrollY >= 80) {
      setNavbarBg("Navbar NavbarBg col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2");
    } else {
      setNavbarBg("Navbar col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2");
    }
    window.addEventListener("scroll", navbarBG);
  };
  const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
      <li className="Navbar__Menu__Items">
        <Link
          onClick={navbarToggle}
          className={isActive ? "Active__Link" : "Navbar__Menu__Items__Link"}
          to={to}
          {...props}
        >
          <img className="Active__Icon" src={ActiveIcon} alt="active" />
          {children}
        </Link>
      </li>
    );
  };
  return (
    <Col onLoad={navbarBG} className={navbarBg}>
      <Row className="justify-content-left NavbarMenu__Logo">
        <a className="Navbar__Logo__Link" href="/">
          <img className="w-75 Navbar__Logo__Image" src={Logo} alt="Logo" />
        </a>
      </Row>
      <div onClick={navbarToggle} className={toggleIcon}>
        <div className="Navbar__Toggler__Line1 Line1"></div>
        <div className="Navbar__Toggler__Line2 Line2"></div>
        <div className="Navbar__Toggler__Line3 Line3"></div>
      </div>
      <ul className={active}>
        <div className="Navbar__Menu__Links">
          <CustomLink to="/">Home</CustomLink>
          {/* <CustomLink to="/dashboard">Dashboard</CustomLink> */}
          <CustomLink to="/blog">Blog</CustomLink>
          <CustomLink to="/contact-us">Contact us</CustomLink>
          <CustomLink to="/about-us">About us</CustomLink>
        </div>
        <div className="Navbar__Menu__Social">
          <a
            href="https://instagram.com"
            className="Navbar__Menu__Social__Button"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram
              className="px-2 instagramButton Navbar__Menu__Social__Button__Logo"
            />
          <svg width="0" height="0">
            <radialGradient id="rg" r="150%" cx="30%" cy="107%">
              <stop className="gradient-stop1" stopColor="#fdf497" offset="0" />
              <stop className="gradient-stop2" stopColor="#fdf497" offset="0.05" />
              <stop className="gradient-stop3" stopColor="#fd5949" offset="0.45" />
              <stop className="gradient-stop4" stopColor="#d6249f" offset="0.6" />
              <stop className="gradient-stop5" stopColor="#285AEB" offset="0.9" />
            </radialGradient>
          </svg>
            Keesht_ir
          </a>

          <a
            href="https://Twitter.com"
            className="Navbar__Menu__Social__Button"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter
              className="px-2 twitterButton Navbar__Menu__Social__Button__Logo"
            />
            Keesht
          </a>
          <a
            href="https://Linkedin.com"
            className="Navbar__Menu__Social__Button"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin
              className="px-2 linkedinButton Navbar__Menu__Social__Button__Logo"
            />
            Keesht
          </a>
        </div>
      </ul>
    </Col>
  );
};

export default Navbar;
