import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import ContentsQuery from "../../Components/Contents.query";
import { useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import "../../styles/About.scss";

const About = () => {
  const [content, setContent] = useState(null);
  const { title } = useParams();
  const { loading, error, data } = useQuery(ContentsQuery);
  useEffect(() => {
    if (data) {
      setContent(data.abouts.find((about) => about.title === title));
    }
  }, [data]);
  if (error) return <p>Error :</p>;
  return (
    <>
      {loading && (
        <div className="Loading">
          <img src="../../../loading.gif" alt="loading" />
        </div>
      )}
      {content && (
        <Container className="About">
          <Row className="About__Title">
            <h1>{content.mainTitle}</h1>
          </Row>
          <Row className="About__WrapperLine col col-xl-11"></Row>
          <Row className="About__Section1 col-xl-11 col-12">
            <Row className="About__Section1__Title">
              <h1>{content.subTitle4}</h1>
            </Row>
            <Row className="About__Section1__Content">
              <Col className="About__Section1__Content__Content col col-xl-7 col-lg-12 col-md-12 col-12">
                <div
                  dangerouslySetInnerHTML={{ __html: content.content4.html }}
                ></div>
              </Col>
              <Col className="About__Section1__Content__Image col-5"><div></div><div></div><div></div><div></div></Col>
            </Row>
          </Row>
          <Row className="About__WrapperLine col-xl-11 col"></Row>
          <Row className="About__Section3 col-xl-11 col-12">
            <Row className="About__Section3__Title">
              <h1>{content.subTitle1}</h1>
            </Row>
            <Row className="About__Section3__Content">
              <div
                dangerouslySetInnerHTML={{ __html: content.content1.html }}
              ></div>
            </Row>
          </Row>

          {/* <Row className="About__Section2 col-xl-11 col-12">
            <Row className="About__Section2__Title">
              <h1>{content.subTitle2}</h1>
            </Row>
            <Row className="About__Section2__Content">
            {data &&
              data.aboutTeams.map((team, index) => (
                <Col key={index} className="About__Section2__Content__Wrapper">
                  <Row className="About__Section2__Content__Wrapper__Image">
                    <img src={team.teamMember.url} />
                  </Row>
                  <Row className="About__Section2__Content__Wrapper__SubTitle">
                    <h4>{team.teamMemberTitle}</h4>
                  </Row>
                </Col>
              ))}
              </Row>
          </Row> */}
          <Row className="About__WrapperLine col-xl-11 col"></Row>

          <Row className="About__Section3 col-xl-11 col-12">
            <Row className="About__Section3__Title">
              <h1>{content.subTitle3}</h1>
            </Row>
            <Row className="About__Section3__Content">
              <div
                dangerouslySetInnerHTML={{ __html: content.content3.html }}
              ></div>
            </Row>
          </Row>
        </Container>
      )}
    </>
  );
};

export default About;
