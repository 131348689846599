import React from "react";
import { Col } from "react-bootstrap";

const Dashboard = () => (
  <>
    <Col className="col-8">
      <h1>Dashboard</h1>
    </Col>
  </>
);

export default Dashboard;
