import {
  ApolloClient,
  InMemoryCache
} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://api-ca-central-1.graphcms.com/v2/cl4okklur0bmu01xx67z697ea/master',
  cache: new InMemoryCache(),
});

export default client;
