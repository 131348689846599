import React, { useEffect, useState } from "react";
import { Instagram, Twitter, Linkedin, MapPin, Phone } from "react-feather";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/Footer.scss";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ContentsQuery from "./Contents.query";

const Footer = () => {
  const [content, setContent] = useState(null);
  const { title } = useParams();
  const { loading, error, data } = useQuery(ContentsQuery);
  useEffect(() => {
    if (data) {
      setContent(data.contacts.find((contact) => contact.title === title));
    }
  }, [data]);
  if (error) return <p>Error :</p>;

  return (
    <>
    {loading && (
      <div className="Loading">
        <img src="../../../loading.gif" alt="loading" />
      </div>
    )}
    { content && (
      <div className="col-12 Footer">
        <Row className="col-7 Footer__Social">
          <a href="https://instagram.com">
            <Instagram
              className="w-50 px-2 InstagramButton SocialItemsLogo"
              width="25px"
              strokeWidth="1"
              size={30}
            />
            Instagram
          </a>
          <a href="https://twitter.com">
            <Twitter
              className="w-50 px-2 TwitterButton SocialItemsLogo"
              width="25px"
              strokeWidth="1"
              size={30}
            />
            Twitter
          </a>
          <a href="https://linkedin.com">
            <Linkedin
              className="w-50 px-2 linkedinButton SocialItemsLogo"
              width="25px"
              strokeWidth="1"
              size={30}
            />
            Linkedin
          </a>
        </Row>

        <Row className="Footer__Info">
          <Row className="Footer__Info__Address">
            <Row>
              <Col className="col-1">
                <MapPin
                  className="w-50 px-2 MapPinButton SocialItemsLogo"
                  strokeWidth="1"
                  size={40}
                />
                <div></div>
              </Col>
              <Col>
                <h5>{content.location2}</h5>
                <h5>{content.location}</h5>
              </Col>
            </Row>
          </Row>
          <Row className="Footer__Info__Tel">
            <Phone
              className="w-50 px-2 MapPinButton SocialItemsLogo"
              strokeWidth="1"
              size={40}
            />
            <h5>
              <a href="tel:+902122562100">{content.phoneNumber}</a>
            </h5>
          </Row>
        </Row>
      </div>
    )}
    </>
    );
}

export default Footer;
