import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import ContentsQuery from "../../../Components/Contents.query";
import { Container, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Calendar, User } from "react-feather";

const SinglePost = (props) => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const { loading, error, data } = useQuery(ContentsQuery);
  useEffect(() => {
    if (data) {
      setPost(data.posts.find((post) => post.slug === slug));
    }
  }, [data]);
  if (error) return <p>Error :</p>;

  return (
    <main>
      {loading && (
        <div className="Loading">
          <img src="../../../loading.gif" alt="loading" />
        </div>
      )}
      {data &&
        data.imgAssets.map((wrapper) => (
          <img
            width="100%"
            key={wrapper}
            src={wrapper.wrapperLine.url}
            alt="wrapperLine"
          />
        ))}
      {post && (
        <Container key={post.id} className="Blog__Post">
          <Row className="Blog__Post__Header">
            <Col className="Blog__Post__Header__Title col-12 col-md-10 col-lg-5">
              <div className="Blog__Post__Header__Title__Header">
                <h2>{post.title}</h2>
              </div>
              <div className="Blog__Post__Header__Title__AuthorDate">
                <div className="Blog__Post__Header__Title__AuthorDate__Author">
                  <User />
                  <h6>{post.author.name}</h6>
                </div>
                <div className="Blog__Post__Header__Title__AuthorDate__Date">
                  <Calendar />
                  <h6>{post.datePublished}</h6>
                </div>
              </div>
            </Col>
            <Col className="Blog__Post__Header__Cover col-12 col-md-10 col-lg-5">
              <img src={post.coverPhoto.url} alt={post.title} />
            </Col>
          </Row>
          <Row className="Blog__Post__Summary">
            <h3 className="col-10">{post.summary}</h3>
          </Row>
          <Row className="Blog__Post__Content">
            <div
              className="col-10"
              key={post.content}
              dangerouslySetInnerHTML={{ __html: post.content.html }}
            ></div>
          </Row>
        </Container>
      )}
    </main>
  );
};

export default SinglePost;
