import React from 'react'
import { Col, Row } from "react-bootstrap";
import Slider from "../../../Components/Slider";
import { Search } from "react-feather";

const Section1 = () => {
  return (
    <>
        <Col className="Home__Wrapper__Section__1__Slider col-12 col-sm-10 col-md 10 col-lg-10 col-xl-9 col">
          <Slider />
        </Col>
        <Col className="Home__Wrapper__Section__1__Search">
          <Search />
        </Col>
    </>
  )
}

export default Section1