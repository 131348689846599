import React, { useState } from "react";
import "../styles/Slider.scss";
import "swiper/scss/lazy";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, lazy } from "swiper";
import { useQuery } from "@apollo/client";
import ContentsQuery from './Contents.query'
import { Link } from 'react-router-dom'

const Slider = (props) => {
  const [activeThumb, setActiveThumb] = useState();

  const { error, data } = useQuery(ContentsQuery);
  if (error) return <p>Error :</p>;
  return (
    <>
      <Swiper
        onSwiper={setActiveThumb}
        loop={true}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{
          swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
        }}
        className="Slider"
        >
        {data && data.posts.map((post, postKey) => (
          <SwiperSlide key={postKey}>
          <div className="Slider__Wrapper">
            <div className="Slider__Wrapper__Content">
              <h3 className="Slider__Wrapper__Content__Number">
                0{post.postId}
              </h3>
              <h5 className="Slider__Wrapper__Content__Title">
                {post.title}
              </h5>
              <p className="Slider__Wrapper__Content__Excerpt">
                {post.summary}
              </p>
              <Link to={`/blog/posts/` + post.slug} className="Slider__Wrapper__Content__Button">
                Read More
              </Link>
            </div>
            <img className="lazy" src={post.coverPhoto.url} alt="SliderBlogImage" />
          </div>
        </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        className="Slider__Thumbs"
      >
        {data && data.posts.map((post, postKey) => (
          <SwiperSlide className="Slider__Thumbs__Wrapper" key={postKey}>
            <div className="Slider__Thumbs__Wrapper__Items">
              <img src={post.coverPhoto.url} alt="SliderBlogImage" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};


export default Slider;
